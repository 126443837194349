// src/pages/ProjectsPage.js

import React from 'react';

const EditProfile = () => {
    return (
        <div className="container">
        </div>
    );
};

export default EditProfile;
